<template>
  <div
    class="flex min-h-screen w-full flex-col justify-between overflow-x-hidden"
    :class="{ 'pt-nav-sm lg:pt-nav-lg': route.path.length > 4 }"
  >
    <MainHeader class="fixed top-0 z-top w-full lg:top-4" />

    <main class="flex-1 lg:mb-10">
      <Container>
        <ClientOnly>
          <div class="flex flex-col items-start gap-10 lg:flex-row">
            <ShopAccountMenu class="shrink-0 max-lg:w-full lg:min-w-[300px]" />
            <div class="w-full bg-gray-10 p-8">
              <slot />
            </div>
          </div>
        </ClientOnly>
      </Container>
    </main>

    <AppFooter />

    <BaseBreaky />
  </div>
</template>

<script setup lang="ts">
const route = useRoute()
const { isCustomerSession } = useUser()
const { getShopRoute } = useShopRouter()

const redirectToLogin = () => {
  return navigateTo(getShopRoute('/login'), {
    external: true,
    replace: true,
  })
}

if (!isCustomerSession.value) {
  await redirectToLogin()
}

watch(
  () => isCustomerSession.value,
  (value) => {
    if (!value) {
      redirectToLogin()
    }
  }
)
</script>
